import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { httpClient } from '../../../shared/api/http-client'

export type CommentsCountType = {
  commentsCount: number
  communityPostId: number
}

export async function fetchCommentsCount(communityPath: string, postsIds: number[]) {
  const searchParams = new URLSearchParams()
  postsIds.forEach(postId => searchParams.append('ids[]', String(postId)))
  return await httpClient.get<CommentsCountType[]>(
    `${COMMUNITY_API_PREFIX}/${communityPath}/posts/comments-count?${searchParams.toString()}`,
  )
}
